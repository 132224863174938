@tailwind base;
@tailwind components;
@tailwind utilities;
@import-normalize; /* bring in normalize.css styles */
@import '@fontsource/titillium-web'; // Defaults to weight 400
@import '@fontsource/titillium-web/400.css'; // Specify weight
@import '@fontsource/titillium-web/400-italic.css';
// @import url('https://fonts.googleapis.com/css2?family=Titillium+Web:wght@400;600;700&display=swap'); /* we keep this temporarily for the select component */
:root {
  --font-primary: 'PPMori', sans-serif;
  --font-secondary: 'Titillium Web';
  --color-black: #1b1b1b;
  --color-grey-800: #262626;
  --color-grey-600: #363636;
  --color-grey-500: #454545;
  --color-grey-300: #686868;
  --color-grey-200: #c4c4c4;
  --color-grey-100: #aaa8a7;
  --color-grey: #8b8b8b;

  --color-yellow-600: #382800;
  --color-yellow-500: #684500;
  --color-yellow-400: #a17408;
  --color-yellow-300: #cf9b1e;
  --color-yellow: #f6c349;

  --color-blue-600: #052549;
  --color-blue-500: #1e4670;
  --color-blue-400: #456b95;
  --color-blue-300: #7b9ec4;
  --color-blue: #a0cdff;

  --color-green-600: #00351f;
  --color-green-500: #1c4c36;
  --color-green-400: #3d7f58;
  --color-green-300: #62c28b;
  --color-green: #92ecb8;

  --color-red-error-dark: #460501;
  --color-red-500: #751813;
  --color-red-400: #a8362e;
  --color-red-300: #d24840;
  --color-red: #ff594f;

  --color-white: #ffffff;
  --color-white-rgb: 255, 255, 255;
  --color-error: #b11b2e;
  --color-error-rgb: 177, 27, 46;
  --color-warning: #a8362e;
  --color-success: #e6ffcc;
  --color-info: #d1deff;
  --color-purple: #9b9eff;

  --spacing-sm: 0.75rem;
  --spacing-md: 1.5rem;
  --spacing-lg: 2.25rem;

  --top-bar-height: 3.25rem;
}

@font-face {
  font-family: 'PPMori';
  src: url(./fonts/PPMori-Regular.otf) format('opentype');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'PPMori';
  src: url(./fonts/PPMori-SemiBold.otf) format('opentype');
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}

@import './variables.module.scss';
@import './helpers.scss';

html {
  box-sizing: border-box;
  font-size: 16px;
}

html,
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background-color: var(--color-black);
}

body {
  font-family: var(--font-primary);
  color: var(--color-white);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6,
ul,
li,
p {
  margin: 0;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  //-ms-overflow-style: none; /* IE and Edge */
  //scrollbar-width: none; /* Firefox */
}

/**::-webkit-scrollbar {
  display: none;
}*/

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

input,
textarea {
  caret-color: var(--color-yellow);
}

button {
  cursor: pointer;
  outline: none;
  background: transparent;
  border: 0;
  color: inherit;
}

a {
  color: inherit;
  text-decoration: none; /* no underline */
}

/*Scrollbar container
*
*/
.scrollbar-container {
  width: 100%;
}

/*
 * Scrollbar rail styles
 */
.ps .ps__rail-x:hover,
.ps .ps__rail-y:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-y.ps--clicking {
  background-color: rgba(255, 255, 255, 0.111);
  opacity: 0.9;
}

/*
 * Scrollbar thumb styles
 */
.ps__thumb-x {
  background-color: var(--color-yellow);
}

.ps__thumb-y {
  background-color: var(--color-yellow);
}

.ps__rail-x:hover > .ps__thumb-x,
.ps__rail-x:focus > .ps__thumb-x,
.ps__rail-x.ps--clicking .ps__thumb-x {
  background-color: var(--color-yellow);
}

.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
  background-color: var(--color-yellow);
}

.react-switch {
  .react-switch-handle {
    div {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
