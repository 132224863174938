// @use '../../styles/colors';
// @use '../../styles/variables' as *;
@use '../../styles/variables.module.scss' as *;

@mixin card($color) {
  border: 1px solid $color;

  &:after {
    border-color: $color;
    background-color: $color;
  }
}

.card {
  background-color: var(--color-grey-600);
  width: 270px;
  height: 540px;
  border-radius: 8px;
  border-top-width: 0;
  padding: 25px 17px 25px 17px;
  position: relative;
  box-sizing: border-box;
  font-size: 14px;
  display: grid;
  gap: 2px;
  grid-template-columns: 1fr;
  grid-template-rows: min-content min-content 20px 1fr min-content min-content;
  grid-template-areas:
    'name'
    'title'
    'activeTier'
    'features'
    'actionButton'
    'learnMoreLink';

  @media screen and (min-width: $breakpoint-md) {
    padding-left: 13px;
    padding-right: 13px;
  }

  &:after {
    display: block;
    content: '';
    height: 10px;
    position: absolute;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    top: -1px;
    left: 0;
    right: 0;
  }

  &.plan {
    @include card(var(--primary-color));

    .name span {
      background-color: var(--primary-color);
    }

    .activeTier {
      background-color: var(--primary-color);
    }
  }
}

.name {
  grid-area: name;
}
.title {
  grid-area: title;

  h2 {
    margin: 10px 0;
    font-size: 24px;
  }
}
.activeTier {
  padding: 4px;
  text-align: center;
  font-weight: 700;
  text-transform: uppercase;
  color: #0b0604;
  grid-area: activeTier;
}
.features {
  grid-area: features;
}
.learnMoreLink {
  grid-area: learnMoreLink;
  margin: 20px auto auto auto;
}
.actionButton {
  margin: 10px auto auto auto;
  grid-area: actionButton;
  height: 30px;
}

.name {
  span {
    font-size: 12px;
    line-height: 20px;
    border-radius: 8px;
    display: inline-block;
    color: var(--color-white);
    padding: 5px 15px;
  }
}

.actions {
  text-align: center;
}

.link {
  color: var(--color-white);
}

.ribbon {
  position: absolute;
  right: 0;
  top: 0;
  overflow: hidden;
  width: 150px;
  height: 150px;
  text-align: right;
}

.ribbon::before,
.ribbon::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  border-color: transparent;
  border-top-right-radius: 8px;
  border-style: solid;
}

.ribbon::after {
  border-width: 30px;
  z-index: 10;
  border-right-color: var(--primary-color);
  border-top-color: var(--primary-color);
}

.ribbon span {
  font-size: 10px;
  font-weight: 1000;
  color: black;
  //text-transform: uppercase;
  text-align: center;
  //line-height: 20px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  width: 120px;
  display: block;
  position: absolute;
  top: 20px;
  right: -36px;
  z-index: 11;
}
