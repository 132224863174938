@import '../../styles/variables.module.scss';

.content {
  height: 90%;
  padding: var(--spacing-md) var(--spacing-lg);
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.pageLoading {
  position: fixed;
  inset: 0;
  width: 100vw;
  height: 100vh;
  scroll-behavior: contain;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  z-index: 1200;
  background: rgba(11, 6, 4, 0.7);
}

.text {
  color: var(--color-grey);
  margin-bottom: 4px;
  font-size: 14px;
}

.cardContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 30px; /* Adjust the gap between cards as needed */
  justify-items: center;
  align-items: center;
  padding: 20px;
  margin: 0 auto;
  height: 100%;
}

.cardContent {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
}

.secondaryList {
  list-style-type: circle !important;

  li {
    margin: 10px 0;
    padding-left: 5px;
  }
}

.list {
  padding: 0 20px;
  min-height: 100px;
  list-style-image: url('./check.png');

  li {
    margin: 10px 0;
    padding-left: 5px;
  }
}

.priceSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 3px 5px;
  margin-top: 25px;
  gap: 5px;
  background: #454545;
  border-radius: 4px;
}

.priceText {
  font-weight: 400;
}
